var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dapp--eth-blocks--block-result-component",
      class: _vm.hasBorder ? "container-border" : "",
    },
    [
      _c(
        "div",
        { staticClass: "py-5 d-flex align-center justify-space-between" },
        [
          _vm.isLoading
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mr-6",
                        attrs: {
                          width: "48px",
                          "max-height": "48px",
                          type: "image",
                        },
                      }),
                      _c("v-skeleton-loader", {
                        staticClass: "mt-5",
                        attrs: {
                          width: "100px",
                          "max-height": "48px",
                          type: "text",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mt-5",
                        attrs: {
                          width: "100px",
                          "max-height": "48px",
                          type: "text",
                        },
                      }),
                      _vm.showAdd
                        ? _c("v-skeleton-loader", {
                            staticClass: "ml-12 mt-5",
                            attrs: {
                              width: "100px",
                              "max-height": "48px",
                              type: "text",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-row",
                { staticClass: "block-container", attrs: { dense: "" } },
                [
                  _c("v-col", { staticClass: "d-flex" }, [
                    _c("img", {
                      staticClass: "mr-6",
                      attrs: { src: _vm.img, width: "48", height: "48" },
                    }),
                    _c("div", [
                      _c(
                        "div",
                        {
                          class: [
                            !_vm.showAdd && _vm.isUnavailable
                              ? "redPrimary--text"
                              : "",
                            "mew-heading-4 font-weight-bold",
                          ],
                        },
                        [_vm._v(" " + _vm._s(_vm.blockNumber) + " ")]
                      ),
                      _c("div", { class: [_vm.showAdd ? "" : "d-flex"] }, [
                        !_vm.showAdd
                          ? _c(
                              "div",
                              {
                                class: [
                                  _vm.isUnavailable
                                    ? "redPrimary--text"
                                    : "textLight--text",
                                  "cursor--pointer text-decoration--underline mr-2",
                                ],
                                on: { click: _vm.showPanel },
                              },
                              [_vm._v(" Remove ")]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            class: [
                              !_vm.showAdd && _vm.isUnavailable
                                ? "redPrimary--text"
                                : "textLight--text",
                              "cursor--pointer text-decoration--underline",
                            ],
                            on: { click: _vm.showBlockQuickView },
                          },
                          [_vm._v(" View Block ")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-space-between",
                      staticStyle: { "max-width": "270px" },
                    },
                    [
                      _vm.isAvailable
                        ? _c("div", { staticClass: "pr-md-3" }, [
                            _c("div", { staticClass: "mew-heading-4" }, [
                              _vm._v(_vm._s(_vm.mintPrice)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mew-heading-4 textLight--text" },
                              [_vm._v(" " + _vm._s(_vm.mintFiatPrice) + " ")]
                            ),
                          ])
                        : _c(
                            "div",
                            { class: [_vm.blockStatusStyle, "mew-heading-4"] },
                            [_vm._v(" " + _vm._s(_vm.blockStatusText) + " ")]
                          ),
                      _vm.showAdd
                        ? _c(
                            "div",
                            {
                              class: [
                                _vm.isAvailable
                                  ? "primary--text cursor--pointer"
                                  : "disabledPrimary--text pointer-event--none",
                                "d-flex align-center ml-lg-12 ml-2",
                              ],
                              on: { click: _vm.toggleAddRemoveBlock },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: [
                                    _vm.isAvailable
                                      ? _vm.isAdded
                                        ? "white primary--text pointer-event--none"
                                        : "primary white--text"
                                      : "disabledPrimary white--text",
                                    "mr-3 d-none d-md-flex d-lg-flex d-xl-flex custom-icon-container",
                                  ],
                                  attrs: { "x-small": "" },
                                },
                                [_vm._v(" " + _vm._s(_vm.mdiIcon) + " ")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  class: [
                                    _vm.isAvailable
                                      ? _vm.isAdded
                                        ? "white primary--text pointer-event--none"
                                        : "primary white--text"
                                      : "disabledPrimary white--text",
                                    "pa-2 d-flex d-md-none d-lg-none d-xl-none custom-icon-container",
                                  ],
                                  attrs: { medium: "" },
                                },
                                [_vm._v(" " + _vm._s(_vm.mdiIcon) + " ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-none d-md-flex d-lg-flex d-xl-flex",
                                },
                                [_vm._v(" " + _vm._s(_vm.addText) + " ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  !_vm.isLoading
                    ? _c("v-slide-x-transition", [
                        _vm.showRemove
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-space-between remove-container",
                              },
                              [
                                _c("v-spacer"),
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("mew-button", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        title: "Keep",
                                        "btn-style": "transparent",
                                        "btn-size": "large",
                                        "color-theme": "error",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.hideRemove.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    }),
                                    _c("mew-button", {
                                      attrs: {
                                        title: "Remove",
                                        "btn-style": "background",
                                        "btn-size": "large",
                                        "color-theme": "error",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.removeBlock.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _c("block-quick-view-popup", {
        attrs: {
          "show-block": _vm.showBlock,
          "block-handler": _vm.blockHandler,
          "is-loading": _vm.isLoading,
        },
        on: {
          "navigate-to-block-info": _vm.navigateToBlockInfo,
          "hide-block-quick-view": _vm.hideBlockQuickView,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }